.on-demand {
    background-color: $divider;
    display: grid;
    grid-template-columns: minmax(400px, 1fr) 1fr 1fr;
    grid-template-rows: auto 1fr;
    overflow-y: auto;
    column-gap: 1px;
    row-gap: 1px;
    grid-template-areas:
        'tabs navigation navigation'
        'jobs-list content content';
}

.on-demand__tabs {
    grid-area: tabs;
}

.on-demand__jobs-list {
    grid-area: jobs-list;
    grid-column: 1 / 2;
    overflow-y: scroll;
}

.on-demand__navigation {
    overflow: auto;
    grid-area: navigation;
}

.on-demand__content {
    background-color: white;
    height: auto;
    padding: 0;
    overflow-y: auto;
    position: relative;
    flex: 1;
    grid-area: content;
}
