.dialog-select {
  &__button {
    min-width: 80px;
  }
  .payment-status-options {
    label {
      display: block;
      margin-bottom: 5px;
      span {
        padding-left: 5px;
      }
    }
  }
}
