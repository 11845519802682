.rota-filters {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 5;
}

.rota-filters__main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.rota-filters__main .rota-filter:not(:first-child) {
    margin-left: 10px;
}

.rota-filters__secondary .bp5-collapse-body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 125px));
    gap: 10px;
    align-items: flex-end;
}

.rota-filters__secondary-flex .bp5-collapse-body {
    display: flex;
    flex-wrap: wrap;
}

.rota-filters__secondary-flex .bp5-collapse-body .bp5-form-group {
    margin-right: 10px;
}

.rota-filters__secondary-flex .bp5-collapse-body .bp5-form-group.slider {
    min-width: 250px;
    margin-left: 10px;
}

.rota-filters__secondary-toggle {
    margin-left: 10px;
    margin-top: 10px;
    max-width: max-content;
}


.rota-filters .rota-filter {
    min-width: 125px;
}
