.weeks-table__header-title {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.weeks-table__title {
  color: var(--text-secondary);
}

.weeks-table__row {
  border: 2px solid #dcdddd;
  margin-top: 20px;
}

.weeks-table__row-header {
  border-bottom: 1px solid #ddd;
}

.weeks-table__row-header-inner {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 15px;
  background: $fadedBackground;
}

.weeks-table__row-header-title {
  font-size: 14px;
  margin: 0;
}

.weeks-table__row-header-actions {
  display: flex;
  margin: 10px 0;
}

.weeks-table__row-header-sort {
  span {
    margin-right: 10px;
  }
}

.weeks-table__row-header-action--duplicate {
  margin-right: 10px;
}

.weeks-table__row-header-action,
.weeks-table__col-content-title,
.weeks-table__col-content-actions span,
.weeks-table__col-content-action span,
.weeks-table__header-action {
  cursor: pointer;
}

.weeks-table__row-header-sort {
  display: flex;
  justify-content: space-around;

  span {
    cursor: pointer;
  }
}

.weeks-table__row-inner {
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  &--list {
    grid-template-columns: 1fr;
  }
}

.weeks-table__col {
  display: grid;
  grid-template-rows: 48px 1fr;

  &-title {
    font-size: 14px;
    font-weight: bold;
    padding: 15px 10px;
    border-bottom: 1px solid #e5e5e5;
    max-height: 48px;
  }

  &:not(:last-child) {
    border-right: 1px solid #e5e5e5;
  }
}

.weeks-table__col-content {
  position: relative;
  min-height: 50px;
}

.weeks-table__col-content-item--paste {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  background: #fff;
  border: 1px dashed $primary;
  color: $primary;
  cursor: cell;
  z-index: 1;
}

.weeks-table__col-content-item {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }

  &:first-child {
    border-bottom: 1px solid #e5e5e5;
  }
}

.weeks-table__col-content-title {
  display: flex;
}

.weeks-table__col-content-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.weeks-table__col-content-subtitle {
  flex-wrap: wrap;
}


.weeks-table__col-content-subtitle,
.weeks-table__col-content-time {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: $mutedText;

  span {
    margin-right: 5px;
  }
}

.weeks-table__col-content-action {
  padding: 5px 15px;
  text-align: center;
}

.weeks-table__header-action,
.weeks-table__col-content-title,
.weeks-table__row-header-sort,
.weeks-table__row-header-action--duplicate {
  color: #1a5584;
}

.weeks-table__col-content-title-add {
  margin-left: 5px;
  cursor: pointer;
}

.weeks-table__row-header-action--delete {
  color: $danger;
}

.weeks-table__col-content-title .bp5-icon-learning {
  margin-right: 5px;
}

// Weeks variants
.weeks--variants {
  margin-top: 30px;
}

//List view
.weeks-table__row--list {
  position: relative;

  .weeks-table__row-header-actions {
    position: absolute;
    top: 0;
    right: 50px;
    padding: 15px 0;
  }
}

.weeks-table__row-header--list {
  .weeks-table__row-header-inner {
    cursor: pointer;
  }

  .weeks-table__row-header-title {
    font-weight: bold;
  }
}

.weeks-table__row-inner--list {
  .weeks-table__col-title {
    background: #fbfcfc;

    &__text {
      display: inline-block;
      width: 200px;
    }

    &__date {
      font-size: 12px;
      font-weight: normal;
    }

    &__count {
      display: inline-block;
      color: #909191;
      width: 60px;
      font-size: 14px;
      font-weight: normal;
    }
  }

  &--sp {
    margin-top: 20px;

    .rota-builder__week-days.rota-builder__week-days--list-view {
      border-bottom: 1px solid #e5e5e5;
    }

    .weeks-table__col-title__text {
      display: inline-block;
      width: auto;
    }
  }
}

.list-view-shift-table {
  width: 100%;

  &--sp {
    .bp5-popover2-target {
      width: 100%;
    }
  }

  &__head,
  &__body {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  &__head {
    border-top: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;

    .list-view-shift-table__item {
      background-color: #f6f7f7;
    }
  }

  &__item {
    width: 100%;
    text-align: left;
    padding: 10px;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    font-weight: normal;

    &:last-child {
      border-right: none;
    }
  }

  &__head-item {
    color: #505050;
  }
}

.common-details {
  position: relative;

  summary {
    list-style: none;
    position: relative;

    &::after {
      content: '';
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z' fill='currentColor' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      height: 100%;
      width: 24px;
      top: 50%;
      right: 15px;
      transition: 0.2s ease all;
      font-weight: bold;
      transform: translate(0, -50%) rotate(0);
    }

    &.summary-no-arrow {
      &::after {
        display: none;
      }
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &[open] {
    > summary {
      &::after {
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }
}

.views-variants {
  display: grid;
  max-width: 230px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.weeks-add-action {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;

  span {
    cursor: pointer;
  }
}
