.status-wrapper {
    display: flex;
    justify-content: space-between;
    width: 60%;
    color: $information-darker;
    > div {
        padding: 5px;
    }
}

.status-wrapper__name {
    width: 30%;
}
.status-wrapper__time {
    width: 50%;
    > span {
        font-weight: bold;
    }
}

.status-wrapper__result {
    width: 20%;
}
