.date-selector-wrapper {
    display: inline-grid;
    position: relative;
    cursor: pointer;
}

.date-selector-wrapper .bp5-collapse {
    position: absolute;
    top: 45px;
    z-index: 1;
}

.date-selector {
    display: flex;
    align-items: center;
}


.date-selector__calendar-icon {
    margin-right: 5px;
    cursor: pointer;
}

.rota-builder__date-range {
    display: flex;
    color: black;
}

.rota-builder__date-range .bp5-tag {
    padding: 5px;
    font-size: 15px;
    line-height: 1.3;
}

.rota-builder__date-range-wrapper {
    margin: 0;
}

.rota-builder__date-item {
    color: var(--text-blue);
    font-size: calc(var(--fz-lg) * 1px);
}
