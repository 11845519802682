html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: 300;
}

#root {
  height: 100%;
}

a {
  color: $primary;

  &:hover {
    color: $primary-darker;
  }
}

.container {
  display: grid;
  grid-template-rows: calc(100vh - 50px) 50px;
  height: 100%;
}

.container--non-prod {
  grid-template-rows: 40px calc(100vh - 90px) 50px;
}

.truncate {
  //--truncate-max-width: 250px; can be changed
  width: var(--truncate-max-width, 250px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
