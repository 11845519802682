.invites {
    display: grid;
    height: 100%;
    overflow-y: auto;
}

@media (min-width: 992px) {
    .invites--show-details {
        grid-template-columns: 1fr 1fr;
    }
}

.invites__list {
    padding: 20px;

    @media (max-width: 991px) {
        .invites--show-details & {
            display: none;
        }
    }
}

.invites__list-callout {
    margin-bottom: 15px;
}

.invites__details {
    border-left: 1px solid $divider;

    @media (max-width: 991px) {
        :not(.invites--show-details &) {
            display: none;
        }
    }
}

.invites__table {
    width: 100%;
}

.invites__table-row--active {
    background-color: $fadedBackground;
}
