// COLORS (GENERAL)

$color-white: #ffffff;
$color-light: #f6f7f7;
$color-light-50: rgba($color-light, 0.5);
$color-light-66: rgba($color-light, 0.66);
$color-black: #000000;

$color-red: #d51313;
$color-green: #00be3a;
$color-blue-light: #00b6d7;
$color-blue: #3281ac;
$color-blue-dark: #004d7e;
$color-orange: #ffb100;
$color-pink: #e81f76;
$color-grey: #7f939c;

$color-text-dark: rgba($color-black, 0.8);
$color-text-main: #546e7a;
$color-text-date: #575757;

$color-border-main: #d3dadd;
$color-border-main-50: rgba($color-border-main, 0.5);
$color-border-grid: #e5e9eb;

$color-orange-bg: #fce8c6;
$color-green-bg: #ddf1dc;
$color-red-bg: #f3d5d3;
$color-blue-bg: #dae6ee;

// COLORS (UCR TASKS)

$color-task-text: #ffffff80;
$color-task-text-alert: #ffffff;
$color-task-time: #00000029;

$color-task-bg-urgent: #ffebee;
$color-task-bg-alert: #ff425e;
$color-task-bg-administrative: #e8f5e9;
$color-task-bg-coordinator: #c0b6f2;
$color-task-bg-follow-up: #fffde7;
$color-hcp-bg-warning: #ffefcc;
$color-hcp-text-warning: #724f00;

// legend and job
$color-legend-and-box-light-blue: #c0b6f2;
$color-legend-and-box-light-green: #e8f5e9;
$color-legend-and-box-light-grey: #dae6ee;

$color-task-border-available: #9e9e9e;
$color-task-border-accepted: #9e9e9e;
$color-task-border-current: #2196f3;
$color-task-border-arrived: #e91e63;
$color-task-border-aborted: #f44336;
$color-task-border-completed: #4caf50;

$color-break-main: #d3dadd;
$color-break-warn: #f9c8b9;

// FONTS

$font-family-main: 'Mulish', sans-serif;

$font-size-h6: 8px;
$font-size-h5: 10px;
$font-size-p: 12px;
$font-size-h4: 14px;
$font-size-h3: 16px;
$font-size-h2: 18px;

$line-height-h6: 10px;
$line-height-h5: 13px;
$line-height-p: 15px;
$line-height-h4: 18px;
$line-height-h3: 20px;
$line-height-h2: 24px;

// OTHERS

$duration-mouse-over: 0.15s;
$function-mouse-over: ease;

$duration-appearance: 0.3s;
$function-appearance: ease;

$transition-appearance: $duration-appearance $function-appearance;
$transition-mouse-over: $duration-mouse-over $function-mouse-over;

$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 8px;

// UCR SIZES

$side-small: 60px;
$side-large: 150px;
$side-panel: 290px;

$input-height: 40px;

// SYLE GUIDE

$guide-blue-grey-darker: #1c303b;
