.common__statuses {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 16px;
}

.common__status {
  color: var(--text-secondary);

  &--success {
    --status-color: var(--c-success);
  }

  &--warning {
    --status-color: var(--c-warning);
  }

  &--error {
    --status-color: var(--c-error);
  }

  &--disabled {
    opacity: 0.5;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 50%;
    background: var(--status-color, #ddd);
  }
}
