.visit-details {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.visit-details__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 30px;
    background-color: $color-pink;
    border-top: 1px solid $color-border-main;
    color: $color-white;
    // align-items: center;
    font-size: 1.1em;
}
.visit-details__header-main-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.visit-details__close-btn {
    margin-left: auto;
}

.visit-details__header-tag {
    background-color: rgba($color-white, 0.25);
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.9em;
    text-transform: uppercase;
    margin: 0 10px;
}
.visit-details__header-left-side {
    display: flex;
    align-items: center;
}
.visit-details__header-right-side {
    display: flex;
    align-items: center;
    justify-content: center;
}
.visit-details__header-user-group {
    margin: 0 10px;
    padding: 4px 8px;
    font-size: 0.9em;
    background-color: rgba($color-white, 0.25);
    border-radius: 4px;
    align-items: center;
    display: flex;
}
.visit-details__header-user-group .bp5-label {
    margin-bottom: 0;
}
.visit-details__header-user-radio {
    margin-left: 13px;
    margin-bottom: 0;
    outline: none;
}
.visit-details__header-user-radio .bp5-control-indicator {
    margin-right: 5px;
}
.visit-details__header-user-radio.bp5-control .bp5-control-indicator,
.visit-details__header-user-radio.bp5-control:hover .bp5-control-indicator,
.visit-details__header-user-radio.bp5-control:focus .bp5-control-indicator,
.visit-details__header-user-radio.bp5-control input:not(:disabled):active ~ .bp5-control-indicator,
.visit-details__header-user-radio.bp5-control input:focus ~ .bp5-control-indicator {
    background: none;
    box-shadow: inset 0 0 0 1px $color-white, inset 0 -1px 0 $color-white;
    outline: none;
}
.visit-details__header-user-radio.bp5-control:hover .bp5-control-indicator::before {
    background-image: radial-gradient(
        rgba($color-white, 0.5),
        rgba($color-white, 0.5) 28%,
        transparent 32%
    );
}
.visit-details__header-user-radio.bp5-control input:checked ~ .bp5-control-indicator {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $color-white, inset 0 -1px 0 $color-white;
}

.visit-details__loading {
    display: flex;
    align-items: center;
    height: 100%;
}

.visit-details__main {
    overflow: hidden auto;
    padding: 10px 30px;
    display: grid;
    column-gap: 3em;
    grid-template-columns: 1fr;

    grid-template-areas:
        'patient-warning'
        'patient-details'
        'visit-details'
        'visit-timing';

    &--default {
        @media (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                'patient-warning patient-warning'
                'patient-details visit-details'
                'visit-timing visit-timing';
        }

        @media (min-width: 1200px) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                'patient-warning patient-warning patient-warning'
                'patient-details visit-details visit-timing';
        }
    }

    &--double-up {
        @media (min-width: 768px) and (max-width: 1199px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                'patient-warning patient-warning'
                'patient-details visit-details'
                'visit-timing visit-timing';
        }

        @media (min-width: 1200px) and (max-width: 1599px) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                'patient-warning patient-warning patient-warning'
                'patient-details visit-details visit-timing';
        }

        @media (min-width: 1600px) {
            grid-template-columns: 1fr 1fr 2fr;
            grid-template-areas:
                'patient-warning patient-warning patient-warning'
                'patient-details visit-details visit-timing';
        }
    }
}

.visit-details__warning {
    margin-top: 5px;
    grid-area: patient-warning;
}

.visit-details__patient {
    grid-area: patient-details;
}

.visit-details__visit {
    grid-area: visit-details;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.visit-details__timing {
    grid-area: visit-timing;
}

.visit-details__timing-container {
    align-items: flex-end;
    flex-wrap: wrap;
    display: flex;
}

.visit-details__timing-container .v2-info__heading {
    width: 100%;
}

.visit-details__timing-container .v2-info {
    margin-bottom: 0;
    width: 100%;

    @media (min-width: 768px) and (max-width: 1199px), (min-width: 1600px) {
        margin-bottom: 20px;
        width: 50%;
    }
}

.visit-details__timing-visual {
    padding-right: 80px;
}

.visit-details__timing-bar {
    position: relative;
    height: 80px;

    &::before {
        position: absolute;
        height: 16px;
        content: '';
        width: 100%;
        display: block;
        background-color: $color-break-main;
        z-index: 1;
        border-radius: 2px;
        top: 2px;
    }
}

.visit-details__timing-bar-progress {
    position: absolute;
    height: 16px;
    content: '';
    display: block;
    background-color: $color-pink;
    z-index: 2;
    border-radius: 2px;
    top: 2px;
}

.visit-details__timing-info {
    display: flex;
    margin-bottom: 5px;

    &--actual {
        color: $color-pink;
        margin-bottom: 15px;
    }
}

.visit-details__timing-detail {
    &:last-child {
        margin-left: auto;
        text-align: right;
    }
}

.visit-details__timing-bar-current {
    position: absolute;
    height: 40px;
    padding: 28px 0 0 10px;
    color: $color-pink;

    &::before {
        position: absolute;
        content: '';
        display: block;
        height: 36px;
        width: 1px;
        background-color: $color-pink;
        box-shadow: 0 0 0 1px white;
        z-index: 3;
        top: 0;
        left: 0;
    }

    &::after {
        position: absolute;
        content: '';
        display: block;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background-color: $color-pink;
        z-index: 3;
        top: 33px;
        left: -3px;
    }
}

.visit-details__not-recorded {
    opacity: 0.75;
}

.visit-details__select-menu {
    max-height: 100px;
    overflow: auto;
}

.visit-details__select__option {
    color: $color-text-dark !important;
}
