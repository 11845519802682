.charts-wrapper {
  display: flex;
  margin-bottom: 30px;
}

.charts-message {
  margin: auto 0 auto 30px;
}

.wrapper-for-two-fields {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .bp5-input-group {
    width: 90%;
  }
}

.person-checklist__row {
  display: grid;
  grid-template-columns: 30px 250px repeat(3, 1fr);
  gap: 20px;
  text-align: left;
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid $secondary;

  .bp5-control {
    margin-bottom: 0;
  }

  &--inactive {
    opacity: 0.5;
    background: #eaeaea;
    cursor: default;
  }
}
