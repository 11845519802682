.onboarding__heading {
    display: flex;
    align-items: center;
}

.onboarding__progress {
    margin-left: auto;
}

.onboarding__files {
    padding-bottom: 30px;
}

.onboarding__file:not(:first-child) {
    border-top: 1px dotted $divider;
    margin-top: 10px;
    padding-top: 10px;
}

.onboarding__file-row {
    display: flex;
}

.onboarding__expiry {
    display: block;
    margin-top: 2px;
    color: $mutedText;
}

.onboarding__file-download {
    margin-left: auto;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.onboarding__file-compliance {
    margin-top: 10px;

    .bp5-control {
        margin: 0;
    }
}

.onboarding__missing-info {
    color: $danger;
}

.onboarding__callout {
    margin-bottom: 20px;
}
