.comments {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
}

.comments__item {
    border-top: 1px dotted $secondary;
    padding: 20px 0;
}

.comments__heading {
    font-size: 14px;
    color: $mutedText;
}

.comments {
    margin-bottom: 20px;
  
    &.shifts {
      padding: 10px 20px;
      background: white;
      margin-bottom: 0;
      padding: 10px 20px;
      background: white;
      margin-bottom: 0;

      .comments__list__comment-row {
        font-size: 13px;
      }

      .comments__list__comment__visibility-label {
          margin-left: 5px;
      }
    }
  }
  