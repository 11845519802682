.agenda {
    display: flex;
    align-items: center;
}

.agenda__link {
    margin-right: 10px;
}

.agenda__flag {
    flex-shrink: 0;
}