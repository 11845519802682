.side-nav {
    display: flex;
    flex-direction: column;
}

.side-nav__link {
    padding: 15px 25px;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: $primary-darker;
    border-bottom: 1px solid rgba($primary-darker, 0.25);
    &:hover,
    &--active {
        text-decoration: none;
        .side-nav__link-text {
            text-decoration: underline;
        }
    }
}

.side-nav__link-text {
    margin-left: 15px;
}

.side-nav__important-details {
    margin-left: auto;
    background-color: $danger;
    color: white;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.side-nav__sub-link {
    padding: 10px 10px 10px 0;
    margin-left: 60px;
    color: $primary-darker;
    border-bottom: 1px solid rgba($primary-darker, 0.25);
}

.side-nav__link--active,
.side-nav__link--active:hover,
.side-nav__sub-link--active,
.side-nav__sub-link--active:hover {
    color: $alt;
    border-bottom-color: rgba($alt, 0.25);
    > span.side-nav__important-details {
        border-bottom: none;
        border-bottom-color: transparent;
    }
}

