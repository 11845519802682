.rota-builder {
    font-family: var(--ff-primary);
    background-color: var(--bg-c-primary);
    overflow-y: auto;
    height: 100%;
}

.rota-builder__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--bd-c);
}

.rota-builder__header-left {
    display: grid;
    grid-template-columns: auto 80px auto;
    align-items: center;
    gap: 20px;
}

.rota-builder__header-buttons {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
    gap: 20px;
}

.rota-builder__header-input {
    margin: 0;
}

.rota-builder__header-right {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    align-items: center;
}

.rota-builder__name {
    color: var(--text-primary);
    word-break: break-all;
}

.rota-builder__status {
    display: inline-flex;
    justify-content: center;
    padding: 6px 10px;
    color: var(--text-dark);
    border-radius: 2px;
}

.rota-builder__status--published {
    background: #96e7c1;
}

.rota-builder__status--draft {
    background: #e8e8e8;
}

.rota-builder__title {
    font-size: calc(var(--fz-h2) * 1px);
}

.rota-builder__name svg {
    margin-left: 10px;
    cursor: pointer;
}

.rota-builder__content {
    padding: 20px;
}

.rota-builder__views-variants {
    text-align: right;
    z-index: 5;
}

.rota-builder__inner-content {
    position: relative;
}

.rota-builder__inner-content-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
}

.rota-builder__inner-content-main-lv .rota-builder__inner-content-main-right {
    max-width: calc(100% - 300px);
}

.rota-builder__inner-content-main-right {
    max-width: calc(100% - 400px);
}

.rota-builder__inner-content-main-left {
    justify-self: flex-end;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.rota-builder__inner-content-main-lv .rota-builder__inner-content-main-left {
    grid-template-columns: auto;
}

.rota-builder__inner-content-inputs {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
}

.rota-builder__inner-content-input {
    margin-right: 20px;
}

.rota-builder__week-days {
    display: grid;
    grid-template-columns: repeat(7, 220px);
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 0;
    overflow: auto;
}

.rota-builder__shift-dialog {
    background: #fff;
    padding: 0;
    max-height: calc(100vh - 40px);
    overflow: auto;
}

.rota-builder__pay-rate {
    display: grid;
    grid-template-columns: 1fr 50px;
    gap: 15px;
    margin-bottom: 15px;
}

.rota-builder__pay-rate__slider {
    margin-top: 15px;
}

.rota-builder__pay-rate .bp5-slider-handle.bp5-end {
    margin: 0;
}

.rota-builder__pay-rate__remove {
    display: inline-flex;
    justify-content: flex-end;
    cursor: pointer;
}

.rota-builder .bp5-input-group .bp5-input {
    height: 38px;
}

.exit-dialog-footer .bp5-dialog-footer-actions {
    justify-content: space-between;
}

.exit-dialog-footer .bp5-dialog-footer-actions .bp5-button:first-child {
    margin-left: 0;
}

.rota-filter label.bp5-label,
.rota-builder label.bp5-label,
.rota-builder__info-title {
    font-size: calc(var(--fz-md) * 1px);
    color: var(--text-secondary);
    font-weight: bold;
    margin: 0 0 5px 0;
}

.rota-builder__info {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    gap: 20px;
}

.rota-builder__info-text {
    font-size: calc(var(--fz-lg) * 1px);
    line-height: 20px;
    color: var(--text-dark);
    margin: 0;
}

.rota-builder__close {
    cursor: pointer;
}

.rota-builder__date-filter .date-selector {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 38px;
    padding: 0 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.rota-builder__date-filter.date-selector-wrapper {
    display: inline-grid;
    position: relative;
}

.rota-builder__date-filter.date-selector-wrapper .bp5-collapse {
    position: absolute;
    top: 45px;
    z-index: 1;
}

.rota-builder__date-filter.date-selector__calendar-icon {
    margin-right: 5px;
    cursor: pointer;
}

.rota-builder__date-filter .date-selector .truncate {
    --truncate-max-width: 150px;
}

@media (min-width: 1450px) {
    .rota-builder__date-filter .date-selector .truncate {
        --truncate-max-width: calc(100px + 4vw);
    }
}

.builder-form__error {
    color: var(--c-error);
}

.builder-form__info {
    color: var(--text-blue);
}

.builder-form__info-content {
    display: inline-block;
    font-size: calc(var(--fz-sm) * 1px);
    margin-top: 5px;
}

.builder-form__info-content:before {
    content: 'i';
    padding: 0 5.5px;
    border-radius: 50%;
    border: 1px solid currentColor;
    margin-right: 5px;
}

.tabs-new--rota-builder .bp5-tab-list {
    overflow-x: auto;
    overflow-y: hidden;
}

.builder-form__col .has-error .bp5-form-content .bp5-timepicker .bp5-timepicker-input-row {
    box-shadow: inset 0 0 0 1px var(--c-error);
}

.builder-form__col .has-error .css-yk16xz-control {
    border-color: var(--c-error);
}

.rota-builder__pay-rate__time-input-wrap {
    display: flex;
    align-items: center;
}

.rota-builder__pay-rate__time-input-wrap p {
    margin-bottom: 0;
    margin-right: 15px;
}
