.stats {
    padding-top: 10px;

    &__button {
        appearance: none;
        border: none;
        background: none;
        margin: 0 0 10px;
        padding: 0;
        text-align: left;
        width: 100%;
        pointer-events: none;

        &--clickable {
            pointer-events: all;

            &:hover,
            &:focus {
                cursor: pointer;
                box-shadow: 0 0 0 2px $mutedBackground;
            }
        }

        &--selected {
            box-shadow: 0 0 0 2px $primary;

            &:hover,
            &:focus {
                box-shadow: 0 0 0 2px $primary;
            }
        }
    }

    &__info {
        color: $mutedText;
        float: right;
        font-style: italic;
    }
}
