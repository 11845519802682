.shift-patterns__form {
  .modal--shadow {
    padding: 0;
  }
}

.info.info--shift-pattern {
  grid-template-columns: 350px 350px;
  gap: 30px;
  align-items: center;

  .info__item {
    display: grid;
    grid-template-columns: 90px 250px;
    align-items: center;
  }

  .info__definition--shift-pattern {
    padding: 0;
  }
}

.info.info--shift-pattern__entry {
  background: #fff;
  grid-template-columns: 1fr 1fr;
  gap: 0 20px;
  padding: 10px 20px;
  margin: 0;

  .bp5-form-group {
    margin-bottom: 0;
  }

  .info__title {
    color: #505050;
    border: none;
    padding: 0;
    margin-bottom: 10px;

  }

  .info__definition {
    border: none;
    padding: 0;
    

    .bp5-timepicker-input-row {
      width: 100%;
    }
  }

  .info__full-width {
    grid-column: 1 / 3;
  }
}

.shift-patterns__info_and_view-variants {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shift-pattern-shifts.rota-shifts {
  overflow: auto;
}

.shift-pattern-entry__form-footer {
  padding: 0 20px;
  text-align: right;
  margin-top: 20px;

  .bp5-button {
    margin-left: 20px;
  }
}

.tabs-new.tabs-new--shift-patterns {
  margin-top: 20px;
}

.shift-pattern-entry__dialog {
  max-height: calc(100vh - 30px);
  overflow: auto;
}

.no-margin-callout {
  .v2-callout {
    margin-top: 0;
  }
}

.shift-pattern-list-view-add-new-shift {
  margin-right: 10px;
}