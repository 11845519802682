.v2-callout {
    --callout-margin: 20px;
    background-color: $color-light;
    border: 1px solid $color-border-main;
    border-radius: $border-radius-sm;
    padding: 10px 20px;
    display: flex;

    &--spacer-top {
        margin-top: var(--callout-margin);
    }

    &--spacer-bottom {
        margin-bottom: var(--callout-margin);
    }

    &--success {
        background-color: $color-green-bg;
        border-color: $color-green;
    }

    &--warning {
        background-color: $color-orange-bg;
        border-color: $color-orange;
    }

    &--danger {
        background-color: $color-red-bg;
        border-color: $color-red;
    }

    &--primary {
        background-color: $color-blue-bg;
        border-color: $color-blue;
    }

    &--interactive {
        cursor: pointer;
    }

    &--compact {
        --callout-margin: 5px;
        padding: 5px 10px;
    }
}

.v2-callout__icon {
    display: flex;
    align-items: center;

    & > svg {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        fill: $color-text-main;
    }

    &--danger > svg {
        fill: $danger-darker;
    }
}

.v2-callout__text {
    padding: 4px 0;

    p:last-of-type {
        margin-bottom: 0;
    }

    p.validation-err-title {
        margin: 20px 0 10px 0;
        font-weight: 700;
    }

    &--danger {
        color: $danger-darker;
    }
}
