.common-header {
  position: relative;
  background-color: white;
  color: $mutedText;
  grid-area: navigation;
  margin-bottom: 20px;
}

.common-header__actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.common-header__pagination {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: auto;
}

.common-header__pagination-summary {
  margin-bottom: 10px;
}

.common-header__buttons {
  display: flex;
}

.common-header__button {
  margin-right: 10px;
}

.common-header__filters {
  display: flex;
  align-items: flex-start;

  &--wrap {
    flex-wrap: wrap;
  }
}

.common-header__filter {
  margin-right: 10px;
  min-width: 100px;
  z-index: 10;
  position: relative;
  &.wider-filter {
    min-width: 150px;
  }
}

.common-header__statuses {
  position: absolute;
  right: 0;
  bottom: 0;
}

.common-header__attachDocuments {
  max-width: 350px;
}

.common-header__secondary-toggle {
  margin-top: 21px;
  margin-left: 10px;
  max-width: max-content;
  &.btn--secondary {
    margin-top: 25px;
  }
}

.common-header__secondary-filters {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  .bp5-collapse-body {
    display: flex;
    align-items: flex-start;
  }
}