@import 'rota-builder.css';
@import 'rota-dates.css';
@import 'rota-filter.css';
@import 'button.css';
@import 'rota-comments.css';


:root {
    --bg-c-primary: #fff;
    --bg-c-secondary: #F6F7F7;
    --bg-c-success: #f2fcf5;
    --bg-c-error: #fdf3f3;

    --bd-c: #d3dadd;

    --text-primary: #546e7a;
    --text-secondary: #7f939c;
    --text-dark: #575757;
    --text-blue: #004D7E;

    --c-success: #00BE3A;
    --c-warning: #FFB100;
    --c-error: #D51313;

    --bdrs: 2;
    --trs: all 0.2s ease;

    --fz-xs: 10;
    --fz-sm: 12;
    --fz-md: 14;
    --fz-lg: 16;
    --fz-h2: 20;

    --ff-primary: 'Mulish';
}

@font-face {
    font-family: 'Mulish';
    src: url('../../../../../../assets/v2/fonts/Mulish/Mulish-Bold.woff2') format('woff2'),
    url('../../../../../../assets/v2/fonts/Mulish/Mulish-Bold.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'Mulish';
    src: url('../../../../../../assets/v2/fonts/Mulish/Mulish-Regular.woff2') format('woff2'),
    url('../../../../../../assets/v2/fonts/Mulish/Mulish-Regular.woff') format('woff');
    font-weight: normal;
}
