.job-summary {
    border: solid var(--main-color, black) 2px;
    border-radius: 4px;
    background-color: white;

    width: 400px;

    &--visit {
        --main-color: #e81f76;
        --header-background-color: white;
    }

    &--admin {
        --main-color: #{$color-grey};
        --header-background-color: white;
    }
}

.job-summary__header {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    background-color: var(--main-color, black);
    padding: 8px;
    color: var(--header-background-color, white);
    position: relative;
    width: calc(100% + 2px);
    left: -1px;
    top: -1px;
}

.job-summary__patient-name {
    flex-grow: 2;
    margin: auto;
    min-height: 16px;
}

.job-summary__hcps {
    text-align: right;
}

.job-summary__hcp {
    padding: 5px 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job-summary__hcp-double-up {
    margin: 3px;
    margin-right: 6px;

    &--margin {
        margin-right: 9px;
    }
}

.job-summary__double-up-icon {
    width: 20px;
    fill: $color-blue;

    &--urgent {
        fill: $danger-darker;
    }
}

.job-summary__hcp-status {
    background-color: rgba(232, 31, 118, 0.4);
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.9em;
    text-transform: uppercase;
    margin-left: 10px;
    color: white;
    width: 125px;
    text-align: center;
    &--available {
        background-color: $color-task-border-available;
    }

    &--accepted {
        background-color: $color-task-border-accepted;
    }

    &--current {
        background-color: $color-task-border-current;
    }

    &--arrived {
        background-color: $color-task-border-arrived;
    }

    &--completed {
        background-color: $color-task-border-completed;
    }

    &--aborted {
        background-color: $color-task-border-aborted;
    }

    &--unassigned {
        background-color: $color-task-border-accepted;
    }
}

.job-summary__section {
    margin: 4px;
    margin-top: 0;
    border: 1px;
    padding: 5px;
    padding-top: 0;

    background-color: white;
    border-bottom: solid 1px $personInputBorderColor;

    &--no-border {
        border-bottom: none;
    }

    &--borderless {
        border-width: 0;
        padding: 0;
    }

    &:last-of-type {
        border-bottom: none;
    }
}
