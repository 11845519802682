.rota-shift-tooltip {
    display: block;
    grid-area: text;
}


.rota-shift-tooltip__inner {
    background: var(--bg-c-secondary);
    min-width: 250px;
}

.rota-shift-tooltip__content {
    cursor: pointer;
}

.rota-shift-tooltip__title {
    font-size: calc(var(--fz-md) * 1px);
    color: var(--text-dark);
    margin: 0 0 10px 0;
    font-weight: normal;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--bd-c);
}

.rota-shift-tooltip__item {
    display: flex;
    justify-content: space-between;
}

.rota-shift-tooltip__item--warning {
    color: var(--c-error);
}

.rota-shift-tooltip__item--warning:after {
    content: '\26A0';
    margin-left: 5px;
}

.rota-shift-tooltip__item-key {
    font-size: calc(var(--fz-sm) * 1px);
    color: var(--text-primary);
}

.rota-shift-tooltip__item-val {
    font-size: calc(var(--fz-sm) * 1px);
    color: #575757;
}

.bp5-portal .bp5-tooltip2 .bp5-popover2-content {
    background: var(--bg-c-secondary);
    border: 1px solid var(--bd-c);
}

.bp5-portal .bp5-tooltip2 {
    box-shadow: none;
}

.rota-shift .bp5-popover2 .bp5-popover2-arrow-fill {
    fill: #fff;
}

.rota-shift-tooltip__item-val--warning {
    color: var(--c-error);
}