.rotaShiftPayRates {
    &__cell {
        width: 350px;
    }

    &__slider {
        margin: 0 15px;

        .bp5-slider-handle.bp5-end {
            margin-left: 0;
        }
    }

    &__error {
        margin-top: 10px;
        color: $danger;
    }

    &__delete {
        cursor: pointer;
    }

    &__title,
    &__warning {
        .bp5-intent-danger,
        .bp5-intent-warning {
            display: inline-flex;
            align-items: center;
            margin-left: 10px;

            .bp5-icon {
                margin-right: 5px;
            }
        }

        .bp5-intent-danger {
            color: $danger;
        }

        .bp5-intent-warning {
            color: $warning;
        }
    }

    &__warning {
        margin-bottom: 20px;

        .bp5-intent-danger {
            margin: 0;
        }
    }
}

.payRates-info {
    max-width: 1200px;

    &__title {
        font-size: 14px;
        margin: 0;
        &.inline {
            display: inline-block;
        }
    }

    &__block {
        display: grid;
        grid-template-columns: 200px 500px;
        gap: 40px 20px;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $mutedBackground;

        &--fullWidth {
            grid-template-columns: 200px 1fr;
            border-bottom: none;
        }
    }

    &__definition {
        .bp5-form-group {
            margin: 0;
        }

        &--inline {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(150px, 150px));
            gap: 40px 20px;
            align-items: flex-start;
            text-align: center;

            .bp5-timepicker-input-row {
                width: 100%;
            }

            &-custom {
                grid-template-columns: repeat(4, minmax(150px, 150px)) 1fr auto;
                margin-bottom: 20px;
            }

            .payRates-info__title {
                margin-bottom: 10px;
            }
        }
    }

    &__delete {
        transform: translateY(35px);
        cursor: pointer;
    }

    &__warning {
        margin-bottom: 20px;
        color: $danger;
    }

    .overlap-error .payRates-info__title {
        color: $danger;
    }
}

.payRates-info-customTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.payRates-future-date-box {
    display: flex;
    max-width: 535px;
    justify-content: space-between;
    color: $danger;
    font-weight: bold;
}

.payRates-future-warning {
    color: $danger-darker;
    &:before {
        content: '\26A0';
    }
}

.payRates-tooltip {
    display: inline-block;
    margin-left: 5px;
    color: white;
    background: $historic;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    font-weight: bold;
    font-size: 11px;
    position: relative;
    top: -5px;
    &:hover {
        cursor: pointer;
    }
    &__content {
        color: black;
        max-width: 350px;
    }
}