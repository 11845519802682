.hcp-invoices-table {
    width: 100%;
    table-layout: fixed;

    .bp5-control {
        margin: 0;
    }
}

.hcp-invoices-table__row {
    &--selected {
        background-color: $fadedBackground;
    }

    &--requires-attention,
    &--hcp-aborted {
        background-color: rgba($dangerBackground, 0.5);
    }
}

.hcp-invoices-table td div {
    display: flex;
    justify-content: space-evenly;
}

table.bp5-html-table.hcp-invoices-table th {
    font-weight: normal;

    &:first-child {
        padding-left: 20px;
    }
}

table.bp5-html-table.hcp-invoices-table td {
    color: $primary-darker;
    border-bottom: 1px solid $secondary;

    &:first-child {
        padding-left: 20px;
    }

    &:last-child {
        padding-right: 20px;
    }
}

table.bp5-html-table.hcp-invoices-table tr:last-child td {
    border: none;
}