.person-fields_group-title__button {
  max-width: fit-content;
  background: none!important;
  box-shadow: none!important;
  font-weight: bold;
  color: $personCategoryTitleColor!important;
  font-size: 22px;
  padding-left: 0;
  .bp5-icon {
    color: $personCategoryTitleColor;
  }
}

.person-fields__collapse {
  border-top: 1px solid $personBorderColor;
  margin-bottom: 25px;
  .bp5-collapse-body {
    transform: none!important;
  }
}

.person-fields {
  &__title {
    color: $personTitleColor;
    font-weight: 400;
    margin-bottom: 5px;
    &.info__title--secondary {
      margin-left: 0px;
      color: #A60705;
    }
  }
  &__definition {
    margin-left: 0;
    color: $personTextColor;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 15px;
    .bp5-form-group {
      font-weight: normal;
    }
    input {
      box-shadow: none;
      border: 1px solid $personInputBorderColor;
      border-radius: 5px;
    }
    .file-upload {
      label {
        padding: 4px;
        display: block;
        background: $lightGreyBackground;
        border-radius: 5px;
      }
    }
    .file-upload__input {
      color: #1e3d6e;
      background-color: $lightGreyBackground;
      border: 1px solid $personBorderColor;
      justify-content: center;
      padding: 30px 0;
      margin-bottom: 0;
    }
    .bp3-callout {
      background-color: $lightGreyBackground;
      color: $personTextColor;
      >.bp5-icon:first-child {
        color: $personTextColor;
      }
      .bp5-button {
        border-color: $personTextColor!important;
        .bp5-icon,
        .bp5-button-text {
          color: $personTextColor!important;
        }
      }
    }
  }
  &__alternative-names {
    &-headings {
      display: flex;
    }
    &-row {
      display: flex;
      &-fields {
        display: flex;
        width: 100%;
        >* {
          width: 100%;
          margin-right: 10px;
        }
      }
      &-controls {
        display: flex;
        font-size: 16px;
      }
      .btn--secondary {
        margin-right: 10px;
        align-self: auto;
        background-color: white!important;
        height: 40px;
        width: 40px!important;
        border-radius: 5px;
          &:nth-child(2) {
            margin-right: 0;
          }
          svg {
            margin: 0;
          }
      }
      &:nth-child(1) {
        .btn--secondary {
          margin-top: 23px;
        }
      }
    }
  }
}

.no-alternative-names {
  font-style: 'italic';
  font-weight: bold;
  color: #1c3563;
}