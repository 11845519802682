// The message and button that trigger the auto-populate modal
.auto-populate {
    margin-bottom: 15px;
}

// The auto-populate modal
.auto-populate__dialog-contents {
    padding: 20px 20px 0;
}

// Highlighting auto-populated fields
// ==================================

// Textarea and inputs
textarea.auto-populated,
.auto-populated input {
    box-shadow: 0 0 0 0 $primary, 0 0 0 0 $primary, inset 0 0 0 1px $primary,
        inset 0 1px 1px $primary;
}

// Selectboxes
.auto-populated.css-10nd86i .css-vj8t7z {
    border: 1px solid $primary;
}
