.admin-report__date-range-picker {
    margin-bottom: 20px;
}

.admin-report__tag {
    margin-left: 10px;
    vertical-align: middle;
}

.admin-report__helper-text {
    color: $mutedText;
    margin-left: 10px;
    vertical-align: middle;
}

.admin-report__audit-reports-heading {
    margin-top: 30px;
}

.admin-report__hcp-invoices-heading {
    margin-top: 30px;
}

.admin-report__hcp-invoices-date-picker-and-button {
    display: flex;
    justify-content: space-between;
}

.admin-report__hcp-invoices-date-picker-and-button div {
    align-self: flex-end;
}

.admin-report__table {
    width: 100%;
    padding-top: 2%;
    border: 1px;
}
