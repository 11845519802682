.tabs-new {

  .bp5-tab-list {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: var(--bd-c, #F6F7F7);
      left: 0;
      z-index: 1;
      bottom: 0;
    }
  }

  .bp5-tab-indicator-wrapper {
    display: none;
  }

  .bp5-tab {
    --tab-bg: var(--bg-c-secondary, #ddd);
    display: flex;
    position: relative;
    background-color: var(--tab-bg) !important;
    padding: 0 0 0 20px;
    margin-right: 5px;
    border-radius: 5px 5px 0 0;
    border: 1px solid #fff;
    border-bottom: none;
    transform: translateX(10px);
    overflow: visible;
    font-size: calc(var(--fz-md) * 1px);
    color: var(--text-secondary);
    font-weight: bold;

    &:hover {
      color: var(--text-primary);
    }


    &[aria-selected='true'] {
      --tab-bg: #fff;
      font-size: calc(var(--fz-md) * 1px);
      color: var(--text-secondary);
      border-color: var(--bd-c);
      z-index: 3;

      &:after {
        --bg: #fff;
        --border-right-color: var(--bd-c);
        z-index: 5;
      }

      &:before {
        background: #fff;
        bottom: -2px;
      }
    }


    &:focus {
      outline: none;
    }

    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 16px);
      height: 2px;
      background: var(--bg-c-secondary, #F6F7F7);
      left: 0;
      z-index: 5;
      bottom: -1px;
    }

    &:after {
      --bg: var(--bg-c-secondary);
      --border-right-color: #fff;
      content: '';
      position: relative;
      width: 20px;
      height: 40px;
      background: var(--bg);
      border-right: 1px solid var(--border-right-color);
      display: block;
      top: .5px;
      right: -9px;
      border-radius: 0 calc(var(--bdrs) * 1px) 0 0;
      z-index: 5;
      transform: skew(0.4rad);
    }
  }

  .bp5-tab-panel {
    position: relative;
    margin-top: 0;
    padding-top: 20px;
    z-index: 2;
  }

}
