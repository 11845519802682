@media print {
    .info-bar,
    .header,
    .vaccinations__tabs,
    .vaccinations__tools,
    .vaccinations__main,
    .vaccinations__navigation,
    .bp5-tab-list,
    .modal__footer {
        display: none !important;
    }

    .container,
    .controller {
        grid-template-rows: 1fr !important;
    }

    .vaccinations {
        grid-template-rows: 1fr !important;
        grid-template-columns: 1fr !important;
        grid-template-areas: 'main' !important;
    }

    .vaccinations__modal {
        grid-column-start: 1 !important;
    }

    .modal {
        overflow: visible;
    }
}
