.builder-form__col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.builder-form__col .bp5-switch {
    outline: none;
}

.builder-form__col .bp5-control-indicator {
    outline: none;
}

.builder-form__col .bp5-input-group .bp5-input {
    height: 38px;
}

.builder-form__col__error {
    color: var(--c-error);;
}
