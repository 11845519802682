.btn {
    --btn-height: 30;
    --btn-color: #fff;
    display: grid;
    place-items: center;
    justify-content: center;
    grid-template-columns: max-content max-content;
    padding: 5px 10px;
    color: var(--btn-color);
    height: calc(var(--btn-height) * 1px);
    background-image: none;
    box-shadow: none;
    border: none;
    border-radius: calc(var(--bdrs) * 1px);
    font-size: calc(var(--fz-md) * 1px);
    transition: var(--trs);
    cursor: pointer;
}

.btn svg {
    fill: currentColor;
    margin-right: 5px;
    width: 16px;
    height: 16px;
}

.btn:hover {
    opacity: 0.9;
}

.btn[disabled] {
    opacity: 0.4;
    pointer-events: none;
}

.btn.btn--primary {
    background-color: var(--text-blue);
}

.btn.btn--success {
    background-color: var(--c-success);
}

.btn.btn--secondary {
    background-color: var(--text-secondary);
    border-color: var(--text-dark, #ddd);
    width: 100%;
}

.btn.btn--outlined {
    --btn-height: 40;
    --btn-color: var(--text-primary, #ddd);
    background-color: var(--bg-c-primary);
    border: 1px solid var(--text-secondary, #ddd);
}

.btn.btn--outlined.btn--outlined-active {
    --btn-color: #fff;
    background-color: var(--text-secondary);
}
