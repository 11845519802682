.department-assignment {
  &-form {
    padding: 15px 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    &__title {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      border-bottom: 1px solid #ddd;
    }

    &__payRates {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 50px;
    }

    &__footer {
      display: grid;
      grid-template-columns: 100px 100px;
      justify-content: flex-end;
      gap: 20px;
      margin-bottom: 20px;

      &--edit-cr {
        grid-template-columns: 100px;
      }
    }
  }
}
