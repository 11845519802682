.scheduled {
    display: grid;
    grid-template-columns: 1fr 2fr;
    overflow-y: auto;
}

@media (min-width: 1600px) {
    .scheduled {
        display: grid;
        grid-template-columns: 1fr 3fr;
    }
}

.scheduled__tools {
    background-color: $fadedBackground;
    border-right: 1px solid $divider;
    padding: 20px;
}

.scheduled__content {
    overflow-y: auto;
}
