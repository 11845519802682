.nav {
    background-color: $mutedBackground;
    padding: 20px 30px;
    display: flex;
    height: 100%;
    align-items: center;
}

.nav__list {
    padding: 0;
    margin: 0;
}

.nav__item {
    display: inline-block;
    margin-right: 10px;
}

.nav__item--loose {
    margin-right: 20px;
}

.nav__link {
    color: $primary;
    border-radius: 5px;
    padding: 12px 15px;
    text-decoration: none;
    text-align: center;
    display: block;
}

.nav__link:hover {
    background-color: $secondary;
    color: $primary;
    text-decoration: none;
}

.nav__link--active {
    background-color: $primary;
    color: white;

    &:hover {
        background-color: $primary;
        color: white;
        cursor: default;
    }

    .bp5-tag {
        background-color: rgba(255, 255, 255, 0.25) !important;
        color: rgba(255, 255, 255, 0.9) !important;
    }
}

.nav__users-available {
    color: $success;
    font-weight: bold;
    position: relative;
    margin-left: 8px;
    padding-left: 8px;

    .nav__link--active & {
        color: white;
    }
}

.nav__users-available::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: $success;
    border: 1px solid rgba(0, 0, 0, 0.25);

    .nav__link--active & {
        border-color: rgba(255, 255, 255, 0.75);
    }
}

.nav__users-total {
    color: $historic;

    .nav__link--active & {
        color: white;
    }
}
