.induction-tasks {
  &__title {
    color: #878787;
    padding-bottom: 20px;
  }

  &__add {
    margin-bottom: 20px;
  }

  &__form {
    display: grid;
    grid-template-columns: 90% auto;
    align-items: center;
    margin-bottom: 20px;

    .bp5-form-group {
      margin: 0;
    }

    &-actions {
      text-align: right;
    }

    &-action {
      display: inline-flex;
      padding: 5px;
      border: 1px solid $secondary;
      border-radius: 3px;
      cursor: pointer;

      &:last-child {
        margin-left: 20px;
      }
    }

    &-error {
      position: absolute;
      color: $danger;
    }
  }

  &__tr {
    .induction-tasks__td-name:hover,
    .induction-tasks__td-actions:hover {
      cursor: default !important;
    }

    .induction-tasks__td-actions {
      text-align: right;
    }
  }

  &__td-action {
    &:hover {
      cursor: pointer;
    }

    &:last-child {
      margin-left: 20px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button:last-child {
      margin-left: 10px;
    }
  }
  &__row {
    color: #106ba3;
    border-bottom: 1px solid #dbdbdb;
    padding: 11px;
    display: flex;
    .induction-tasks__td-actions {
      margin-left: auto;
    }
  }
}
