.calendar {
    height: 100%;
    padding: 20px;
}

.agenda-time-btns {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;

    // Match styling of rbc buttons
    .bp5-button:not([class*="bp5-intent-"]) {
        background-image: none;
        background-color: #fff;

        &:hover {
            background-color: #e6e6e6;
        }
    }

    .bp5-button:not([class*="bp5-intent-"]).bp5-active {
        background-image: none;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
        background-color: #e6e6e6;
        border-color: #adadad;
    }
}

/* Big Calendar overrides */

.rbc-off-range-bg {
    background: $mutedBackground;
}

.rbc-today {
    background: $fadedBackground;
}

.rbc-row-segment {
    padding: 0 2px 2px 2px;
}

.rbc-event {
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    color: $text;

    &--available {
        background-color: $warningBackground;
        border-color: $warning;

        &:hover {
            background-color: darken($warningBackground, 5%);
        }
    }

    &--accepted {
        background-color: $successBackground;
        border-color: $success;

        &:hover {
            background-color: darken($successBackground, 5%);
        }
    }

    &--current,
    &--arrived {
        background-color: $informationBackground;
        border-color: $information;

        &:hover {
            background-color: darken($informationBackground, 5%);
        }
    }

    &--require_attention,
    &--withdrawn,
    &--hcp_aborted {
        background-color: $dangerBackground;
        border-color: $danger;

        &:hover {
            background-color: darken($dangerBackground, 5%);
        }
    }

    &--completed,
    &--controller_aborted {
        background-color: $fadedBackground;
        border-color: $historic;
        color: $mutedText;

        &:hover {
            background-color: darken($fadedBackground, 5%);
        }
    }

    &--faded {
        opacity: 0.5;
    }
}

.rbc-agenda {
    &-date-cell {
        background-color: white;
    }

    &-hcp-type-cell {
        max-width: 160px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &-status-cell {
        text-align: center;
    }
}

.rbc-agenda-view table.rbc-agenda-table,
.rbc-agenda-view table.rbc-agenda-table thead > tr > th,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-color: rgba(0, 0, 0, 0.25);
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th.rbc-header--status {
    text-align: center;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 5px 10px;
}

.rbc-agenda-content table.rbc-agenda-table {
    border-top: none;
}
