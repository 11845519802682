.invite-details__change-status,
.invite-details__compliance-check {
    margin-bottom: 20px;
}

.invite-details__button-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
