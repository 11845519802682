.day-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    padding: 15px 8px 8px;
    border: 1px solid var(--bd-c);
    border-bottom: none;
    border-radius: calc(var(--bdrs) * 1px) calc(var(--bdrs) * 1px) 0 0;
}

.day-card__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 5px;
}

.day-card__header-content {
    display: flex;
    align-items: flex-start;
}

.day-card__time {
    font-size: calc(var(--fz-sm) * 1px);
    margin-left: 5px;
    color: var(--text-secondary);
}

.day-card__title {
    font-size: calc(var(--fz-md) * 1px);
    line-height: 15px;
    color: var(--text-primary);
    margin: 0;
}

.holiday:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 16px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 20'%3E%3Cg fill='%23546E7A' fill-rule='evenodd'%3E%3Cpath fill-rule='nonzero' d='M16 18V7H2v11h14M13 0h2v2h1c1.11 0 2 .9 2 2v14c0 1.11-.89 2-2 2H2c-1.11 0-2-.9-2-2V4c0-1.11.89-2 2-2h1V0h2v2h8V0'/%3E%3Ctext font-family='LucidaGrande, Lucida Grande' font-size='8' letter-spacing='.03833376'%3E%3Ctspan x='3.72143186' y='16'%3EBH%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/svg%3E");
    margin-right: 5px;
}

.day-card__title .bp5-heading {
    margin: 0;
}

.day-card__icon {
    margin-left: 5px;
    cursor: pointer;
}
