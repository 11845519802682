.rota-builder--list .bp5-tab-list {
    display: grid;
}

.rota-builder--list .weeks-table__row {
    margin: 0;
}

.rota-builder--list .weeks-table__row:not(:last-child) {
    border-bottom: none;
}

.rota-builder--list .tabs-new--rota-builder {
    margin-top: 20px;
}

.rota-builder--list .rota-builder__views-variants {
    top: 110px;
}

.rota-builder__week-days.rota-builder__week-days--list-view {
    grid-template-columns: 1fr;
    gap: 0;
    margin: 0;
    padding: 0;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}

.list-view-shift-table__item.rota-builder__day-shift-tooltip__name {
    max-width: 175px;
}

.list-view-shift-table__item .weeks-table__col-content-title {
    display: block;
    max-width: 135px;
}

.rota-builder--list .weeks-table__col-content-title {
    display: block;
    max-width: 135px;
}

.weeks-table__col-content-title-main {
    display: flex;
    align-items: center;
    cursor: default;
}

.weeks-table__col-content-title-main h4 {
    margin: 0 5px 0 0;
}

.weeks-table__col-content-title-main .applied-shift-number, .weeks-table__col-content-title-main .holiday {
    margin-left: 5px;
}


.rota-builder__day-list-card .weeks-table__col-title {
    cursor: pointer;
    background: #fbfcfc;
}

.weeks-table__col-content-title--warning {
    color: var(--c-error);
    white-space: nowrap;
}


.rota-builder-lv-wrapper {
    position: relative;
}

.rota-builder-lv-add-shift {
    position: sticky;
    left: 0;
}

.rota-builder-lv-buttons-wrap {
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 20px;
}

@media (min-width: 1680px) {
    .rota-builder-lv-buttons-wrap {
        position: absolute;
        display: grid;
        grid-template-columns: auto auto;
        right: 236px;
        top: -76px
    }
}
