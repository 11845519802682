.rota-shifts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    min-height: 25px;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
    max-height: 380px;
    overflow: overlay;
}

/* width */
.rota-shifts::-webkit-scrollbar {
    width: 5px;
}

/* Handle */
.rota-shifts::-webkit-scrollbar-thumb {
    background: var(--text-blue);
    border-radius: 5px;
}

.rota-shift {
    --rota-shift-bg: var(--bg-c-secondary);
    display: grid;
    background: var(--rota-shift-bg);
    grid-template-areas:
        'text actions'
        'tooltip  tooltip';
    grid-template-columns: 130px auto;
    justify-content: space-between;
    gap: 5px;
    padding: 18px 10px;
    border: 1px solid var(--bd-c);
    border-radius: calc(var(--bdrs) * 1px);
}

/*.rota-shift--success {*/
/*    --rota-shift-bg: var(--bg-c-success, #f2fcf5);*/
/*}*/

/*.rota-shift--error {*/
/*    --rota-shift-bg: var(--bg-c-error, #fdf3f3);*/
/*}*/

.rota-shift__actions {
    grid-area: actions;
}

.rota-shift__content-header {
    display: flex;
    align-items: center;
}

.rota-shift__content-time {
    font-size: calc(var(--fz-sm) * 1px);
    color: var(--text-primary);
    font-weight: bold;
}

.rota-shift__content-duty-station {
    --truncate-max-width: 150px;
    cursor: pointer;
    font-size: calc(var(--fz-md) * 1px);
    margin: 3px 0 0 0;
    color: var(--text-primary);
    font-weight: bold;
}

.rota-shift__content-employee {
    font-size: calc(var(--fz-sm) * 1px);
    color: var(--text-primary);
    margin-top: 5px;
}

.rota-shift__content-comments-icon {
    display: block;
}

.rota-shift__content-employee--warning {
    color: var(--c-error);
    font-style: italic;
}

.list-view-shift-table__item .rota-shift__content-employee--warning {
    font-style: normal;
}

.rota-shift-add .btn {
    height: 40px;
    border-radius: 0 0 calc(var(--bdrs) * 1px) calc(var(--bdrs) * 1px);
}

.common-table--list-view td {
    white-space: nowrap;
}

.no-margin {
    margin: 0;
}