.comments {
    margin-bottom: 10px;
}

.comments__form {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}


.comments__form-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.comments__form-visibility_toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0 0;
}

.comments__list__comment__visibility-label {
    margin-left: 8px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 5px;
    background: var(--text-secondary);
    color: black;
    padding: 0 5px;
}

.comments__form-visibility_toggle label {
    font-size: 12px;
    margin-right: 10px;
}

.comments__form__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comments__form__controls .bp5-switch {
    margin-right: -8px;
}

.comments__form-visibility-filter {
    flex-direction: row;
    align-items: center;
}

.comments__form-visibility-filter label {
    margin-right: 10px;
    font-size: 12px;
}

.comments__form-visibility-filter .bp5-form-content {
    width: 120px;
}

.comments__form-visibility-filter [class*="control"] {
    min-height: auto;
}

.comments__form-visibility-filter [class*="ValueContainer"] {
    padding: 0 10px;
}

.comments__form-visibility-filter [class*="indicatorContainer"] {
    padding: 0 5px;
}

.comments__title {
    font-weight: 500;
    margin-bottom: 10px;
}

.comments__form__comment-label {
    font-weight: 500;
    display: block;
    margin-bottom: 15px;
    width: 100%;
}

.comments__form__comment-input {
    -webkit-appearance: none;
    appearance: none;
    background: var(--bg-c-primary);
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
    color: #182026;
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    line-height: 30px;
    outline: none;
    padding: 0 10px;
    transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
    vertical-align: middle;
    flex-grow: 1;
}

.comments__form__comment-input:focus {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgb(19 124 189 / 30%), inset 0 1px 1px rgb(16 22 26 / 20%);
}

.comments__form-group label.bp5-label {
    margin-bottom: 15px;
}

.comments__form-group .bp5-form-content {
    display: flex;
    align-items: center;
}

.comments__form-group-input {
    flex-grow: 1;
}

.comments__form-post-button {
    margin-left: 10px;
}

.comments__form__comment-warning-empty-input {
    margin-top: 5px;
}

.comments__form__comment-warning {
    display: inline-block;
    font-size: 10px;
    margin-top: 5px;
    font-weight: bold;
}

.comments__list__comment {
    margin-bottom: 10px;
}

.comments__list__comment-row {
    display: flex;
    align-items: center;
}

.comments__list__comment__user-avatar {
    background: var(--text-secondary);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-right: 10px;
}

.comments__list__comment__user-name {
    margin-right: 10px;
}

.comments__list__comment__was-edited {
    font-style: italic;
    margin-left: 10px;
}

.comments__list__comment__text {
    padding-left: 45px;
    width: 95%;
}

.comments__list__comment__text textarea {
    width: 100%;
    padding: 10px;
    resize: none;
    height: 70px;
    margin-bottom: 5px;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
    color: #182026;
    border: none;
    outline: none;
}

.comments__list__comment__text textarea:focus {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgb(19 124 189 / 30%), inset 0 1px 1px rgb(16 22 26 / 20%);
}

.comments__list__empty-comment-warning {
    margin-bottom: 10px;
}

.comments__form__save-edit-comment-button {
    margin-right: 10px;
}

.comments__list__comment__controls {
    margin-top: 10px;
}

.comments__list__comment__controls-edit,
.comments__list__comment__controls-delete {
    margin-right: 10px;
}

.comments__list__comment__controls-edit:hover,
.comments__list__comment__controls-delete:hover {
    cursor: pointer;
}
