.related-entries {
  &__collapse-btn {
    .bp5-icon {
      position: absolute;
      right: 20px;
    }
  }
  &.cost-centres {
    .related-departments {
      th:nth-child(3),
      td:nth-child(3) {
        display: none!important;
      }
    }
  }
}
